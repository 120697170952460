
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { ISupplier, ISupplierListRequest } from "@/types/supplier";
import { IProductListRequest, IProduct } from "@/types/product";
import { IUser } from "@/types/user";
import { IUserListRequest } from "@/types/user";
import { IWarehouse, IWarehouseListRequest } from "@/types/warehouse";
import { IOrder, IOrderUpdateRequest } from "@/types/order";
import { OrderProductUpdateRequest } from "@/models/order_product";
import { IOrderProductUpdateRequest } from "@/types/order_product";
import { OrderUpdateRequest } from "@/models/order";
import { ITax, ITaxListRequest } from "@/types/tax";
import { IPriceTag, IPriceTagListRequest } from "@/types/price_tag";
import {
  IPriceTagSupplier,
  IPriceTagSupplierListRequest
} from "@/types/price_tag_supplier";
import { OrderPriceTag } from "@/models/order_price_tag";
import { ITradeTermListRequest } from "@/types/trade_term";
import { IArrangementMethodListRequest } from "@/types/arrangement_method";

@Component({})
export default class extends Vue {
  //order
  @Action("order/adminGet")
  public getOrder!: (order_id: number) => Promise<boolean>;

  @Action("order/adminUpdate")
  public update!: (data: {
    order_id: number;
    params: IOrderUpdateRequest;
  }) => Promise<boolean>;

  @Action("order/adminDelete")
  public deleteOrder!: (order_id: number) => Promise<boolean>;

  @Getter("order/single")
  public order!: IOrder;

  @Mutation("order/clear")
  public clearOrder!: () => void;

  //product
  @Action("product/adminGetProductSegments")
  public getProductSegments!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/filterdSelectItem")
  public makerFilterdSelectItem!: (maker_id: number) => ISelectItem[];

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  //supplier
  @Action("supplier/adminGetList")
  public getSuppliers!: (params: ISupplierListRequest) => Promise<boolean>;

  @Action("supplier/adminGet")
  public getSupplier!: (supplier_id: number) => Promise<boolean>;

  @Getter("supplier/selectItem")
  public supplierList!: ISelectItem[];

  @Getter("supplier/relationSupplierStaffSelectItem")
  public relationSupplierStaffList!: ISelectItem[];

  @Getter("supplier/relationMakerSelectItem")
  public relationMakerList!: ISelectItem[];

  @Getter("supplier/relationUserSelectItem")
  public relationUserList!: ISelectItem[];

  @Getter("supplier/find")
  public findSupplier!: (id: number) => ISupplier;

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  @Mutation("user/clear")
  public clearUser!: () => void;

  //warehouse
  @Action("warehouse/adminGetList")
  public getWarehouses!: (params: IWarehouseListRequest) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Getter("warehouse/find")
  public findWarehouse!: (id: number) => IWarehouse;

  @Mutation("warehouse/clear")
  public clearWarehouse!: () => void;

  //Tax
  @Action("tax/adminGetList")
  public getTaxes!: (params: ITaxListRequest) => Promise<boolean>;

  @Getter("tax/find")
  public findTax!: (id: number) => ITax;

  @Getter("tax/selectItem")
  public selectTaxList!: ISelectItem[];

  @Mutation("tax/clear")
  public clearTax!: () => void;

  // price_tag
  @Action("price_tag/adminGetList")
  public getPriceTags!: (request: IPriceTagListRequest) => Promise<boolean>;

  @Getter("price_tag/selectItem")
  public priceTagList!: ISelectItem[];

  @Getter("price_tag/find")
  public findPriceTag!: (id: number) => IPriceTag;

  @Mutation("price_tag/clear")
  public clearPriceTag!: () => void;

  //price_tag_supplier
  @Action("price_tag_supplier/adminGetList")
  public getPriceTagSuppliers!: (
    params: IPriceTagSupplierListRequest
  ) => Promise<boolean>;

  @Getter("price_tag_supplier/filterdSelectItem")
  public priceTagSupplierFilterdSelectItem!: (
    supplier_id: number,
    price_tag_id: number
  ) => ISelectItem[];

  @Getter("price_tag_supplier/find")
  public findPriceTagSupplier!: (id: number) => IPriceTagSupplier;

  @Mutation("price_tag_supplier/clear")
  public clearPriceTagSupplier!: () => void;

  // trade_term
  @Action("trade_term/adminGetList")
  public getTradeTerms!: (request: ITradeTermListRequest) => Promise<boolean>;

  @Getter("trade_term/selectItem")
  public tradeTermList!: ISelectItem[];

  @Mutation("trade_term/clear")
  public clearTradeTerm!: () => void;

  // arrangement_method
  @Action("arrangement_method/adminGetList")
  public getArrangementMethods!: (
    request: IArrangementMethodListRequest
  ) => Promise<boolean>;

  @Getter("arrangement_method/selectItem")
  public arrangementMethodList!: ISelectItem[];

  @Mutation("arrangement_method/clear")
  public clearArrangementMethod!: () => void;

  //パラメータ定義
  public params: IOrderUpdateRequest = new OrderUpdateRequest();

  //変数定義
  public order_id = 0;
  public currency = "￥";
  public tax_name_select: string | null = null;
  public select_arrangement_method: string | ISelectItem | null = null;
  public select_trade_term: ISelectItem = {
    text: "",
    value: 0
  };
  public productOptions: ISelectItem[] = [];
  public priceTagSupplierOptions: ISelectItem[][] = [];
  public units: string[] = [];
  public is_minus: number[] = [];
  public order_date_errors: string[] = [];
  public fixed_delivery_date_errors: string[] = [];
  public is_disabled_price_tag: boolean[] = [true];
  public is_readonly_price_tag: boolean[] = [false];
  public uneditable = false;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public valid_order_product = true;
  public lazy = false;
  public elsewhere = false;
  public menu = {
    desired_delivery_date: false,
    fixed_delivery_date: false,
    creation_date: false,
    order_date: false
  };

  //発注ステータス
  public order_status_list = [
    { value: Vue.prototype.$orderStatus.order_registration, text: "発注登録" },
    { value: Vue.prototype.$orderStatus.ordered, text: "発注済み" },
    { value: Vue.prototype.$orderStatus.wait_storing, text: "入荷待ち" },
    { value: Vue.prototype.$orderStatus.cancel_order, text: "発注キャンセル" }
  ];

  //通貨単位リスト
  public currency_type_list = [
    { value: Vue.prototype.$currency_type.jpy, text: "￥：円" },
    { value: Vue.prototype.$currency_type.usd, text: "＄：米ドル" },
    { value: Vue.prototype.$currency_type.eur, text: "€：ユーロ" }
  ];

  //ルール設定
  public rules = {
    order_status: [(v: number) => !!v || "発注ステータスは必須です"],
    warehouse_id: [(v: number) => !!v || "倉庫を選択してください"],
    destination: [(v: string) => !!v || "配送先は必須です"],
    supplier_id: [(v: number) => !!v || "サプライヤは必須です"],
    sales_staff: [(v: number) => !!v || "テクネ担当者は必須です"],
    rev: [(v: string) => !v || v.length <= 1 || "Revは1文字までです"],
    maker_id: [(v: number) => !!v || "メーカは必須です"],
    product_id: [(v: number) => !!v || "商品は必須です"],
    buying_unit_price: [
      (v: number) => !!v || v === 0 || "単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,4})?$/.test(v) ||
        "小数点以下4桁までで入力してください"
    ],
    buying_unit_price_yen: [
      (v: number) => !!v || "単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    buyer: [(v: number) => !!v || "発注責任者は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.order_id = Number(this.$route.params.order_id);
    this.params.user_id = this.user.id;

    this.clearOrder();
    this.clearProduct();
    this.clearSupplier();
    this.clearWarehouse();
    this.clearTax();
    this.clearPriceTag();
    this.clearPriceTagSupplier();
    this.clearUser();
    this.clearTradeTerm();
    this.clearArrangementMethod();

    await Promise.all([
      this.getOrder(this.order_id),
      this.getUsers({ per_page: 0 }),
      this.getWarehouses({ per_page: 0 }),
      this.getSuppliers({ per_page: 0 }),
      this.getTaxes({ per_page: 0 }),
      this.getPriceTags({ per_page: 0 }),
      this.getPriceTagSuppliers({ per_page: 0 }),
      this.getTradeTerms({ per_page: 0 }),
      this.getArrangementMethods({ per_page: 0 })
    ]);

    this.setDefault();

    this.$nextTick(function () {
      (this.$refs.order_form as Vue & { validate: () => boolean }).validate();
      (
        this.$refs.order_product_form as Vue & { validate: () => boolean }
      ).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private async setDefault() {
    this.params.createFromOrder(this.order);
    this.tax_name_select = this.order.tax_name;
    this.select_arrangement_method = this.order.shipped_via;
    this.select_trade_term = this.tradeTermList.find(item => {
      return item.text == this.order.incoterm;
    }) || {
      text: "",
      value: 0
    };
    this.elsewhere = !this.order.warehouse_id ? true : false;
    this.changeStatusColor();
    this.currencyFlagSelected();
    await Promise.all([
      this.getSupplier(this.params.supplier_id),
      this.makerSelected()
    ]);

    for (let [index, order_product] of this.order.products.entries()) {
      if (index > 0) {
        this.appendList();
      }
      this.params.order_products[index].id = order_product.id;
      this.params.order_products[index].product_id = order_product.product_id;
      this.productSelected(index);
      this.params.order_products[index].buying_unit_price = Number(
        this.params.currency_type != 1
          ? Number(order_product.buying_unit_price).toFixed(4)
          : Number(order_product.buying_unit_price).toFixed(2)
      );
      this.params.order_products[index].amount = Number(
        this.params.currency_type != 1
          ? Number(order_product.amount).toFixed(4)
          : Number(order_product.amount).toFixed(2)
      );
      this.params.order_products[index].quantity = order_product.quantity;
      this.params.order_products[index].content = order_product.content;
      this.params.order_products[index].note_id = order_product.note_id;
      this.params.order_products[index].revision = order_product.revision;
      this.params.order_products[index].amount = Number(
        this.params.currency_type != 1
          ? (order_product.buying_unit_price * order_product.quantity).toFixed(
              4
            )
          : (order_product.buying_unit_price * order_product.quantity).toFixed(
              2
            )
      );
    }

    for (let [index, single] of this.order.order_price_tag.entries()) {
      this.params.order_price_tags[index].price = Number(
        this.params.currency_type != 1
          ? Number(this.params.order_price_tags[index].price).toFixed(4)
          : Number(this.params.order_price_tags[index].price).toFixed(2)
      );

      const priceTagSupplierFilterdSelectItems =
        this.priceTagSupplierFilterdSelectItem(
          this.params.supplier_id,
          single.price_tag_id
        );

      this.isMinusCheck(single.price_tag_id, index);

      this.is_disabled_price_tag[index] =
        priceTagSupplierFilterdSelectItems.length == 0;
      this.is_readonly_price_tag[index] =
        priceTagSupplierFilterdSelectItems.length == 1;

      this.priceTagSupplierOptions.push(priceTagSupplierFilterdSelectItems);

      if (this.is_readonly_price_tag[index]) {
        this.params.order_price_tags[index].price_tag_supplier_id = Number(
          this.priceTagSupplierOptions[index][0].value
        );
      }
    }
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    //諸経費配列の整理
    if (!this.params.order_price_tags.some(item => item.price_tag_id > 0)) {
      this.params.order_price_tags.splice(0);
    } else {
      this.params.order_price_tags = this.params.order_price_tags.filter(
        item => {
          return item.price_tag_id;
        }
      );
    }

    //発注更新
    result = await this.update({
      order_id: this.order_id,
      params: this.params
    });

    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteOrder(this.order_id)) {
      this.$router.push(`/admin/order/list`);
    }
  }

  //サプライヤが選択された際の挙動
  public async supplierSelected() {
    this.params.sales_staff_id = null;
    this.params.maker_id = 0;
    this.units.splice(0);
    this.params.order_products.splice(0);
    this.params.order_price_tags.splice(0);
    if (this.params.order_products.length == 0) {
      this.params.order_products.push(new OrderProductUpdateRequest());
    }
    if (this.params.order_price_tags.length == 0) {
      this.params.order_price_tags.push(new OrderPriceTag());
    }
    if (this.params.supplier_id) {
      await this.getSupplier(this.params.supplier_id);

      if (this.relationMakerList.length == 1) {
        this.params.maker_id = Number(this.relationMakerList[0].value);
        await this.makerSelected();
      }

      if (this.relationUserList.length == 1) {
        this.params.sales_staff_id = Number(this.relationUserList[0].value);
      }
    }
  }

  //メーカが選択された際の挙動
  public async makerSelected() {
    this.units.splice(0);
    this.params.order_products.splice(0);
    if (this.params.order_products.length == 0) {
      this.params.order_products.push(new OrderProductUpdateRequest());
    }
    if (!this.params.maker_id) return;
    await this.getProductSegments({
      maker_id: this.params.maker_id,
      per_page: 0
    });
    this.productOptions = this.makerFilterdSelectItem(this.params.maker_id);
  }

  //配送先倉庫が選択された際の挙動
  public warehouseSelected() {
    this.params.destination = null;
    if (this.params.warehouse_id) {
      const warehouse = this.findWarehouse(this.params.warehouse_id);
      this.params.destination = warehouse.name;
    }
  }

  //税目が選択された際の挙動
  public async taxSelected(tax_index: number) {
    if (tax_index) {
      const findTax = this.findTax(tax_index);
      this.params.tax_name = findTax.name;
      this.params.tax_rate = findTax.tax_rate
        ? Number(
            this.params.currency_type != 1
              ? findTax.tax_rate.toFixed(4)
              : findTax.tax_rate.toFixed(2)
          )
        : 0;
    } else {
      this.params.tax_name = "";
      this.params.tax_rate = 0;
    }
  }

  //通貨単位が選択された際の挙動
  public async currencyFlagSelected() {
    if (this.params.currency_type == 1) this.currency = "￥";
    if (this.params.currency_type == 2) this.currency = "＄";
    if (this.params.currency_type == 3) this.currency = "€";

    this.$nextTick(function () {
      (this.$refs.order_form as Vue & { validate: () => boolean }).validate();
      (
        this.$refs.order_product_form as Vue & { validate: () => boolean }
      ).validate();
    });
  }

  //商品が選択された際の挙動
  public async productSelected(index: number) {
    this.units[index] = "";
    if (!this.params.order_products[index].product_id) return;
    const product = this.findProduct(
      this.params.order_products[index].product_id
    );
    this.units[index] = product.unit;
  }

  //諸経費が選択された際の挙動
  public priceTagSelected(index: number) {
    this.$set(
      this.priceTagSupplierOptions,
      index,
      this.priceTagSupplierFilterdSelectItem(
        this.params.supplier_id,
        this.params.order_price_tags[index].price_tag_id
      )
    );
    this.is_disabled_price_tag[index] =
      Object.keys(this.priceTagSupplierOptions[index]).length == 0;
    this.is_readonly_price_tag[index] =
      Object.keys(this.priceTagSupplierOptions[index]).length == 1;
    if (this.is_readonly_price_tag[index]) {
      this.params.order_price_tags[index].price_tag_supplier_id = Number(
        this.priceTagSupplierOptions[index][0].value
      );
    }
  }

  //商品合計金額計算(バックエンド側で計算する場合は不要)
  public calcAmount(index: number, order_product: IOrderProductUpdateRequest) {
    this.params.order_products[index].amount = order_product.product_amount
      ? Number(
          this.params.currency_type != 1
            ? order_product.product_amount.toFixed(4)
            : order_product.product_amount.toFixed(2)
        )
      : 0;
  }

  //小計金額計算
  public get subtotal() {
    let subtotal = 0;

    this.params.order_products.forEach(
      item => (subtotal += item.product_amount || 0)
    );

    this.params.order_price_tags.forEach((price_tag, index) => {
      this.is_minus[index]
        ? (subtotal -= Number(price_tag.price))
        : (subtotal += Number(price_tag.price));
    });

    this.params.subtotal = Number(
      this.params.currency_type != 1 ? subtotal.toFixed(4) : subtotal.toFixed(2)
    );

    return Number(
      this.params.currency_type != 1 ? subtotal.toFixed(4) : subtotal.toFixed(2)
    );
  }

  //発注合計金額計算
  public get amount() {
    if (!this.params.tax_rate) {
      this.params.amount = Number(
        this.params.currency_type != 1
          ? this.params.subtotal.toFixed(4)
          : this.params.subtotal.toFixed(2)
      );

      return Number(
        this.params.currency_type != 1
          ? this.params.subtotal.toFixed(4)
          : this.params.subtotal.toFixed(2)
      );
    }

    const amount = Number(
      this.params.currency_type != 1
        ? (this.params.subtotal * (1 + this.params.tax_rate * 0.01)).toFixed(4)
        : (this.params.subtotal * (1 + this.params.tax_rate * 0.01)).toFixed(2)
    );

    this.params.amount = amount;

    return amount;
  }

  //商品追加
  public appendList() {
    this.params.order_products.push(new OrderProductUpdateRequest());
    this.$nextTick(function () {
      (
        this.$refs.order_product_form as Vue & { validate: () => boolean }
      ).validate();
    });
  }

  //商品削除
  public deleteList(index: number) {
    this.params.order_products.splice(index, 1);
    this.units.splice(index, 1);
    if (this.params.order_products.length == 0) {
      this.params.order_products.push(new OrderProductUpdateRequest());
    }
  }

  //諸経費追加
  public appendOrderPriceTagList() {
    this.params.order_price_tags.push(new OrderPriceTag());
    this.is_disabled_price_tag.push(true);
    this.is_readonly_price_tag.push(false);
  }

  //諸経費削除
  public deleteOrderPriceTagList(index: number) {
    this.params.order_price_tags.splice(index, 1);
    this.priceTagSupplierOptions.splice(index, 1);
    this.is_minus.splice(index, 1);
    this.is_disabled_price_tag.splice(index, 1);
    this.is_readonly_price_tag.splice(index, 1);
    if (this.params.order_price_tags.length == 0) {
      this.params.order_price_tags.push(new OrderPriceTag());
      this.is_disabled_price_tag.push(true);
      this.is_readonly_price_tag.push(false);
    }
  }

  //price_tagのis_minusチェック
  public isMinusCheck(price_tag_id: number, index: number) {
    this.is_minus[index] = price_tag_id
      ? this.findPriceTag(price_tag_id).is_minus
      : 0;
  }

  //price_tag_idバリデーション
  public prriceTagRule(v: number) {
    const countSamePriceTagId = this.params.order_price_tags.filter(
      price_tag => price_tag.price_tag_id == v
    ).length;
    if (countSamePriceTagId >= 2) {
      return "同じ諸経費名は選択できません";
    }
    return true;
  }

  //priceバリデーション
  public priceRule(v: number) {
    if (v < 0) {
      return "0以下は入力できません";
    }
    if (this.params.currency_type == 1) {
      if (!/^([1-9]\d*|0)(\.\d{1,2})?$/.test(String(v))) {
        return "小数点以下2桁までで入力してください";
      }
    }
    if (this.params.currency_type != 1) {
      if (!/^([1-9]\d*|0)(\.\d{1,4})?$/.test(String(v))) {
        return "小数点以下4桁までで入力してください";
      }
    }
    return true;
  }

  //発注日バリデーション
  public orderDateRule() {
    if (
      this.params.order_status == Vue.prototype.$orderStatus.ordered ||
      this.params.order_status == Vue.prototype.$orderStatus.wait_storing
    ) {
      return this.params.order_date
        ? (this.order_date_errors = [])
        : (this.order_date_errors = ["発注日は必須です"]);
    }
    return (this.order_date_errors = []);
  }

  //入荷予定日バリデーション
  public fixedDeliveryDateRule() {
    if (this.params.order_status == Vue.prototype.$orderStatus.wait_storing) {
      return this.params.fixed_delivery_date
        ? (this.fixed_delivery_date_errors = [])
        : (this.fixed_delivery_date_errors = ["入荷予定日は必須です"]);
    }
    return (this.fixed_delivery_date_errors = []);
  }

  // 発注登録の場合ステータスを赤文字にする
  public changeStatusColor() {
    if (
      this.params.order_status == Vue.prototype.$orderStatus.order_registration
    ) {
      document
        .querySelector(".status-select input")
        ?.classList.add("red--text");
    } else if (
      document
        .querySelector(".status-select input")
        ?.classList.contains("red--text")
    ) {
      document
        .querySelector(".status-select input")
        ?.classList.remove("red--text");
    }
  }

  //order_formバリデーションチェック
  public updated() {
    this.$nextTick(function () {
      (this.$refs.order_form as Vue & { validate: () => boolean }).validate();
      (
        this.$refs.order_product_form as Vue & { validate: () => boolean }
      ).validate();
    });
  }

  /* 発注ステータスがキャンセルの場合、登録済の発注データを再セット
      ステータス選択以外は全てdisabled */
  public reSetDefault() {
    if (this.params.order_status != Vue.prototype.$orderStatus.cancel_order) {
      this.uneditable = false;
      return;
    }

    // 発注商品配列リセット
    this.params.order_products.splice(0);
    this.units.splice(0);
    this.params.order_products.push(new OrderProductUpdateRequest());

    // 諸経費配列リセット
    this.params.order_price_tags.splice(0);
    this.priceTagSupplierOptions.splice(0);
    this.is_minus.splice(0);
    this.is_disabled_price_tag.splice(0);
    this.is_readonly_price_tag.splice(0);
    this.is_disabled_price_tag.push(true);
    this.is_readonly_price_tag.push(false);

    // 受注データ再セット
    this.setDefault();

    // キャンセルステータス入れ直し
    this.params.order_status = Vue.prototype.$orderStatus.cancel_order;

    // ステータス以外の全項目disabled
    this.uneditable = true;
  }
}
